import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import PrimeVue from "primevue/config";
import store from "./store";
import services from './services'

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
Vue.use(PrimeVue)
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.config.productionTip = false
window.services = services;


import VueImg from 'v-img';

Vue.use(VueImg);


//Filtro que formatea una numero con " . " cada tres digitos
Vue.filter('moneda', function (value) {
  if (!value) return 0
  value = (new Intl.NumberFormat('es', { style: 'currency', currency: 'COP' }).format(value)).split(',')
  return value[0]
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  PrimeVue,
  vuetify,
  render: h => h(App)
}).$mount('#app')
